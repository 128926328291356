const StandingData = [
  { name: 'علی احمدی', score: 95 },
  { name: 'مریم محمدی', score: 90 },
  { name: 'رضا کریمی', score: 85 },
  { name: 'زهرا حسینی', score: 80 },
  { name: 'امیر رضایی', score: 75 },
  { name: 'فاطمه نوری', score: 88 },
  { name: 'محمد قاسمی', score: 92 },
  { name: 'سارا محمودی', score: 79 },
  { name: 'حسین جعفری', score: 87 },
  { name: 'نرگس کاظمی', score: 93 },
  { name: 'مهدی صادقی', score: 81 },
  { name: 'لیلا رضایی', score: 86 },
  { name: 'احمد موسوی', score: 78 },
  { name: 'ندا حیدری', score: 89 },
  { name: 'کامران علوی', score: 94 },
  { name: 'شیما رحیمی', score: 82 },
  { name: 'بهروز محمدی', score: 91 },
  { name: 'الهام نجفی', score: 77 },
  { name: 'حمید صالحی', score: 84 },
  { name: 'آزاده کریمی', score: 96 },
  { name: 'مجید رضوانی', score: 83 },
  { name: 'پریسا حسینی', score: 88 },
  { name: 'سعید نصیری', score: 76 },
  { name: 'مینا فرهادی', score: 90 },
  { name: 'جواد طاهری', score: 85 },
  { name: 'نازنین اکبری', score: 79 },
  { name: 'فرهاد زمانی', score: 92 },
  { name: 'شیرین قربانی', score: 81 },
  { name: 'امین رستمی', score: 87 },
  { name: 'هانیه صفوی', score: 94 },
  { name: 'بابک حسنی', score: 80 },
  { name: 'سمیرا عزیزی', score: 89 },
  { name: 'کیوان نوروزی', score: 78 },
  { name: 'نگار سلیمانی', score: 93 },
  { name: 'ارسلان میرزایی', score: 86 },
  { name: 'مهسا جلالی', score: 91 },
  { name: 'شهرام کمالی', score: 77 },
  { name: 'یاسمن قاسمی', score: 95 },
  { name: 'بهنام حیدری', score: 82 },
  { name: 'نسرین محسنی', score: 88 },
  { name: 'فرزاد نیکزاد', score: 84 },
  { name: 'رویا صادقی', score: 90 },
  { name: 'ایمان رحمانی', score: 79 },
  { name: 'سحر نادری', score: 96 },
  { name: 'کاوه مرادی', score: 83 },
  { name: 'مریم فلاحی', score: 87 },
  { name: 'سینا یوسفی', score: 81 },
  { name: 'نیلوفر باقری', score: 92 },
  { name: 'امیر حسینی', score: 85 },
  { name: 'ترانه احمدی', score: 78 },
  { name: 'محسن رضایی', score: 94 },
  { name: 'سپیده کرمی', score: 80 },
  { name: 'پویا نصیری', score: 89 },
  { name: 'الناز حبیبی', score: 76 },
  { name: 'عرفان صالحی', score: 93 },
  { name: 'مونا عباسی', score: 86 },
  { name: 'رامین فتحی', score: 91 },
  { name: 'نگین محمدی', score: 77 },
  { name: 'حامد کاظمی', score: 95 },
  { name: 'شقایق رستمی', score: 82 },
  { name: 'آرش نوری', score: 88 },
  { name: 'گلناز حسنی', score: 84 },
  { name: 'مازیار صفایی', score: 90 },
  { name: 'پرنیا علیزاده', score: 79 },
  { name: 'داریوش محمودی', score: 96 },
  { name: 'سوگل رحیمی', score: 83 },
  { name: 'بهزاد نجفی', score: 87 },
  { name: 'مهتاب فراهانی', score: 81 },
  { name: 'کامبیز احمدی', score: 92 },
  { name: 'ستاره موسوی', score: 85 },
  { name: 'فرشید کریمی', score: 78 },
  { name: 'نیکی رضوانی', score: 94 },
  { name: 'بهرام صادقی', score: 80 },
  { name: 'پردیس حیدری', score: 89 },
  { name: 'آرمان جعفری', score: 76 },
  { name: 'شادی نصری', score: 93 },
  { name: 'سروش طاهری', score: 86 },
  { name: 'مهرناز اکبری', score: 91 },
  { name: 'کورش زمانی', score: 77 },
  { name: 'نسترن قربانی', score: 95 },
  { name: 'فرزین رستمی', score: 82 },
  { name: 'پانیذ صفوی', score: 88 },
  { name: 'هومن حسنی', score: 84 },
  { name: 'ملیکا عزیزی', score: 90 },
  { name: 'شهاب نوروزی', score: 79 },
  { name: 'شبنم سلیمانی', score: 96 },
  { name: 'سامان میرزایی', score: 83 },
  { name: 'نازلی جلالی', score: 87 },
  { name: 'رضا کمالی', score: 81 },
  { name: 'مهرنوش قاسمی', score: 92 },
  { name: 'پارسا حیدری', score: 85 },
  { name: 'سمانه محسنی', score: 78 },
  { name: 'کیان نیکزاد', score: 94 },
  { name: 'الهه صادقی', score: 80 },
  { name: 'بهنود رحمانی', score: 89 },
  { name: 'نگار نادری', score: 76 },
  { name: 'آریا مرادی', score: 93 },
  { name: 'ساناز فلاحی', score: 86 },
  { name: 'پوریا یوسفی', score: 98 }
];


export default StandingData;