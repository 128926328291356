const Provinces = [
  {
    id: 4,
    title: 'آذربایجان شرقی',
    slug: 'East Azarbaijan',
    latitude: 37.9035733,
    longitude: 46.2682109
  },
  {
    id: 5,
    title: 'آذربایجان غربی',
    slug: 'Western Azerbaijan',
    latitude: 37.9035733,
    longitude: 46.2682109
  },
  {
    id: 25,
    title: 'اردبیل',
    slug: 'Ardabil',
    latitude: 38.4853276,
    longitude: 47.8911209
  },
  {
    id: 11,
    title: 'اصفهان',
    slug: 'Esfahan',
    latitude: 32.6546275,
    longitude: 51.6679826
  },
  {
    id: 31,
    title: 'البرز',
    slug: 'Alborz',
    latitude: 35.9960467,
    longitude: 50.9289246
  },
  {
    id: 17,
    title: 'ایلام',
    slug: 'Ilam',
    latitude: 33.2957618,
    longitude: 46.670534
  },
  {
    id: 19,
    title: 'بوشهر',
    slug: 'Bushehr',
    latitude: 28.9233837,
    longitude: 50.820314
  },
  {
    id: 24,
    title: 'تهران',
    slug: 'Tehran',
    latitude: 35.696111,
    longitude: 51.423056
  },
  {
    id: 30,
    title: 'خراسان جنوبی',
    slug: 'South Khorasan',
    latitude: 32.5175643,
    longitude: 59.1041758
  },
  {
    id: 10,
    title: 'خراسان رضوی',
    slug: 'Khorasan Razavi',
    latitude: 32.5175643,
    longitude: 59.1041758
  },
  {
    id: 29,
    title: 'خراسان شمالی',
    slug: 'North Khorasan',
    latitude: 37.4710353,
    longitude: 57.1013188
  },
  {
    id: 7,
    title: 'خوزستان',
    slug: 'Khuzestan',
    latitude: 31.4360149,
    longitude: 49.041312
  },
  {
    id: 20,
    title: 'زنجان',
    slug: 'Zanjan',
    latitude: 36.5018185,
    longitude: 48.3988186
  },
  {
    id: 21,
    title: 'سمنان',
    slug: 'Semnan',
    latitude: 35.2255585,
    longitude: 54.4342138
  },
  {
    id: 12,
    title: 'سیستان وبلوچستان',
    slug: 'Sistan and Baluchistan',
    latitude: 27.5299906,
    longitude: 60.5820676
  },
  {
    id: 8,
    title: 'فارس',
    slug: 'Fars',
    latitude: 29.1043813,
    longitude: 53.045893
  },
  {
    id: 27,
    title: 'قزوین',
    slug: 'Qazvin',
    latitude: 36.0881317,
    longitude: 49.8547266
  },
  {
    id: 26,
    title: 'قم',
    slug: 'Qom',
    latitude: 34.6399443,
    longitude: 50.8759419
  },
  {
    id: 13,
    title: 'کردستان',
    slug: 'Kurdistan',
    latitude: 31.4360149,
    longitude: 49.041312
  },
  {
    id: 9,
    title: 'کرمان',
    slug: 'Kerman',
    latitude: 30.2839379,
    longitude: 57.0833628
  },
  {
    id: 6,
    title: 'کرمانشاه',
    slug: 'Kermanshah',
    latitude: 34.314167,
    longitude: 47.065
  },
  {
    id: 18,
    title: 'کهگیلویه و بویراحمد',
    slug: 'Kohgiloyeh Boyerahmad',
    latitude: 30.6509479,
    longitude: 51.60525
  },
  {
    id: 28,
    title: 'گلستان',
    slug: 'Golestan',
    latitude: 37.2898123,
    longitude: 55.1375834
  },
  {
    id: 2,
    title: 'گیلان',
    slug: 'Gilan',
    latitude: 37.2809,
    longitude: 49.5924
  },
  {
    id: 16,
    title: 'لرستان',
    slug: 'Lorestan',
    latitude: 31.4360149,
    longitude: 49.041312
  },
  {
    id: 3,
    title: 'مازندران',
    slug: 'Mazandaran',
    latitude: 36.2262393,
    longitude: 52.5318604
  },
  {
    id: 1,
    title: 'مرکزی',
    slug: 'Markazi',
    latitude: 34.6123,
    longitude: 49.8547
  },
  {
    id: 23,
    title: 'هرمزگان',
    slug: 'Hormozgan',
    latitude: 30.2839379,
    longitude: 57.0833628
  },
  {
    id: 14,
    title: 'همدان',
    slug: 'Hamedan',
    latitude: 27.138723,
    longitude: 55.1375834
  },
  {
    id: 15,
    title: 'چهارمحال و بختیاری',
    slug: 'Chaharmahal and Bakhtiari ',
    latitude: 31.9614348,
    longitude: 50.8456323
  },
  {
    id: 22,
    title: 'یزد',
    slug: 'Yazd',
    latitude: 0,
    longitude: 0
  }
]

export default Provinces
